<template>
  <div class='row'>
    <div class='col-lg-12'>
      <div class='card card-top card-top-primary'>
        <div class='card-header'>
          <vb-headers-card-header :data="{ title: 'Legal Dökümanlar' }" />
        </div>
        <div class='card-body'>
          <div>
            <div class='table-responsive text-nowrap'>
              <a-table :loading='loading'
                       :columns='columns'
                       :data-source='legalDocumentItems'
                       :pagination='false'
                       :row-key='(record) => record.id'>
                <template #operationid='{ record }'>
                  <div><strong>{{ record.id }}</strong></div>
                </template>
                <template #operation='{ record }'>
                  <div>
                    <router-link
                      :to='`/legal-dokuman-duzenleme/${record.id}`'
                      tag='a'
                      type='button'
                      class='btn btn-primary mr-1'
                    >
                      <i class='fe fe-settings text-white' />
                    </router-link>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { legalDocumentAdministration } from '../../services/api-service'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    slots: { customRender: 'operationid' },
    width: 20,
  },
  {
    title: 'Başlık',
    dataIndex: 'title',
    width: 200,
  },
  {
    title: 'İçerik',
    dataIndex: 'content',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Yönet',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    class: 'text-right',
    width: 70,
  },
]

export default {
  name: 'LegalDokumanlar',
  data() {
    return {
      loading: false,
      columns,
      legalDocumentItems: [],
    }
  },
  mounted() {
    this.loadLegalDocument()
  },
  methods: {
    async loadLegalDocument() {
      this.loading = true
      try {
        const result = await legalDocumentAdministration.getAllLegalDocumentsForAdministration()
        this.legalDocumentItems = result.data.legalDocuments.sort(function(a, b) {
          return (a.id - b.id);
        });
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
}
</script>
